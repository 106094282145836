import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import LinkComponent from '@/components/atoms/Link'
import availabilityIco from '@/assets/svg/availability-icon.svg'
import brochureIco from '@/assets/svg/brochure-icon.svg'
import websiteIco from '@/assets/svg/website-icon.svg'
import * as styles from './styles.module.css'

interface Props {
	websiteUrl?: string
	brochureUrl?: string
}

function PropertyLinks({ brochureUrl, websiteUrl }: Props) {
	return (
		<section className={styles.section}>
			<div className={styles.wrapper}>
				<AnchorLink to="#availability" className={styles.item}>
					<img src={availabilityIco} alt="Availability icon" />
					<span>Availability</span>
				</AnchorLink>
				{brochureUrl ? (
					<LinkComponent type="external" url={brochureUrl} className={styles.item}>
						<img src={brochureIco} alt="Brochure icon" />
						<span>Brochure</span>
					</LinkComponent>
				) : (
					<button className={styles.item} disabled={!brochureUrl}>
						<img src={brochureIco} alt="Brochure icon" />
						<span>Brochure</span>
					</button>
				)}
				{websiteUrl ? (
					<LinkComponent type="external" url={websiteUrl} className={styles.item}>
						<img src={websiteIco} alt="Website icon" />
						<span>Website</span>
					</LinkComponent>
				) : (
					<button className={styles.item} disabled={!websiteUrl}>
						<img src={websiteIco} alt="Website icon" />
						<span>Website</span>
					</button>
				)}
			</div>
		</section>
	)
}

export default PropertyLinks
