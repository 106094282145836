// extracted by mini-css-extract-plugin
export var mainImage_wrapper = "styles-module--mainImage_wrapper--c66bd";
export var main_arrow = "styles-module--main_arrow--5a32f";
export var main_arrow_next = "styles-module--main_arrow_next--6c053";
export var main_arrow_prev = "styles-module--main_arrow_prev--037c6";
export var main_slider = "styles-module--main_slider--041e3";
export var modal = "styles-module--modal--42be4";
export var modal_container = "styles-module--modal_container--c5b1a";
export var overlay = "styles-module--overlay--60678";
export var thumbnail = "styles-module--thumbnail--ec579";
export var thumbnail_active = "styles-module--thumbnail_active--6e524";
export var thumbnail_arrow = "styles-module--thumbnail_arrow--32f63";
export var thumbnail_arrow_next = "styles-module--thumbnail_arrow_next--74076";
export var thumbnail_arrow_prev = "styles-module--thumbnail_arrow_prev--47fdc";
export var thumbnail_slider = "styles-module--thumbnail_slider--aafb4";