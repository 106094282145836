import React, { useState } from 'react'

import useMatchMedia from '@/hooks/useMatchMedia'
import PropertyAvailabilityTable from '@/components/molecules/PropertyAvailabilityTable'
import PropertyAvailabilityMobileList from '@/components/molecules/PropertyAvailabilityMobileList'
import PropertiesModalGallery from '@/components/molecules/PropertyModalGallery'
import Heading from '@/components/atoms/Heading'
import Text from '@/components/atoms/Text'

import * as styles from './styles.module.css'

interface PropertyAvailabilityProps {
	propertyUnits?: PropertyUnitType[]
	typeKeys?: SpaceProfileKeyType[]
}

function PropertyAvailability({ propertyUnits = [], typeKeys = []}: PropertyAvailabilityProps) {
	const [modalOpen, setModalOpen] = useState(false)
	const [modalPhotos, setModalPhotos] = useState<PropType<PropertyUnitType, 'images'>>()
	const isDesktop = useMatchMedia('(min-width: 1200px)')

	const handleOpenModal = (propertyUnitPhotos: PropType<PropertyUnitType, 'images'> | undefined) => {
		if (propertyUnitPhotos === undefined || !propertyUnitPhotos.length) return
		setModalPhotos(propertyUnitPhotos)
		setModalOpen(true)
	}

	const handleCloseModal = () => {
		setModalOpen(false)
	}

	const renderContent = () => {
		if (propertyUnits.length == 0) {
			return <Text level={2}>Call for Availability</Text>
		} else if (isDesktop) {
			return <PropertyAvailabilityTable propertyUnits={propertyUnits} onModalOpen={handleOpenModal} typeKeys={typeKeys} />
		} else {
			return <PropertyAvailabilityMobileList propertyUnits={propertyUnits} onModalOpen={handleOpenModal} />
		}
	}

	return (
		<>
			<section className={styles.wrapper} id="availability">
				<div className="container">
					<div className={styles.heading}>
						<Heading variant="bold" level={4}>
							Availability
						</Heading>
						<span />
					</div>
					{renderContent()}
				</div>
			</section>
			<PropertiesModalGallery open={modalOpen} onClose={handleCloseModal} images={modalPhotos} />
		</>
	)
}

export default PropertyAvailability
