import React from 'react'

import PropertyAvailabilityMobileListItem from '@/components/molecules/PropertyAvailabilityMobileListItem'

interface PropertyAvailabilityMobileListProps {
	propertyUnits: PropertyUnitType[]
	onModalOpen: (propertyUnitPhotos: PropType<PropertyUnitType, 'images'>) => void
}

function PropertyAvailabilityMobileList({ propertyUnits, onModalOpen }: PropertyAvailabilityMobileListProps) {
	return (
		<div className="property-units-list">
			{propertyUnits.map((unit) => (
				<PropertyAvailabilityMobileListItem key={unit.id} propertyUnit={unit} onPhotoGalleryOpen={onModalOpen} />
			))}
		</div>
	)
}

export default PropertyAvailabilityMobileList
