import React from 'react'

import { ReactComponent as CameraIcon } from '@/assets/svg/camera.svg'
import Heading from '@/components/atoms/Heading'
import numberWithCommas from '@/utils/numberWithCommas'
import renderDollarAmount from '@/utils/renderDollarAmount'
import downloadFile from '@/utils/downloadFile'

import * as styles from './styles.module.css'

interface TextContentField {
	contentType?: 'text'
	content: string | number
}

interface DownloadBtnField {
	contentType: 'downloadBtn'
	onDownloadBtnClick: () => void
	disabled: boolean
}

interface PhotosBtnField {
	contentType: 'photosBtn'
	onPhotosBtnClick: () => void
	disabled: boolean
}

type FieldPropsType = { label: string } & (TextContentField | DownloadBtnField | PhotosBtnField)

function Field(props: FieldPropsType) {
	const renderContent = () => {
		switch (props.contentType) {
			case 'photosBtn':
				return (
					<button className={styles.photosBtn} onClick={props.onPhotosBtnClick} disabled={props.disabled}>
						<CameraIcon />
					</button>
				)
			case 'downloadBtn':
				return (
					<button className={styles.downloadBtn} onClick={props.onDownloadBtnClick} disabled={props.disabled}>
						Download
					</button>
				)
			default:
				return <span>{props.content}</span>
		}
	}

	return (
		<div className={styles.fieldWrapper}>
			<Heading variant="bold" level={6}>
				{props.label}
			</Heading>
			<span>{renderContent()}</span>
		</div>
	)
}

interface Props {
	propertyUnit: PropertyUnitType
	onPhotoGalleryOpen: (propertyUnitPhotos: PropType<PropertyUnitType, 'images'> | undefined) => void
}

function PropertyAvailabilityMobileListItem({
	propertyUnit: {
		id,
		street_address,
		suite,
		available_sq_feet,
		space_profile: { office_space_available, warehouse_space_available },
		price_per_sq_ft,
		lease_type,
		floor_plan,
		images,
	},
	onPhotoGalleryOpen,
}: Props) {
	return (
		<div key={id} className={styles.wrapper}>
			<div className={styles.grid}>
				<div className={styles.addressWrapper}>
					<Field label="Address" content={street_address || ''} />
				</div>
				<Field label="Suite" content={suite || ''} />
				<Field label="Sq. Ft" content={numberWithCommas(available_sq_feet)} />
				<Field label="Office" content={numberWithCommas(office_space_available)} />
				<Field label="Warehouse" content={numberWithCommas(warehouse_space_available)} />
				<Field label="Rent" content={renderDollarAmount(price_per_sq_ft)} />
				<Field label="Lease Type" content={lease_type || ''} />
				<div className={styles.floorplanWrapper}>
					<Field
						contentType="downloadBtn"
						label="Floorplan"
						onDownloadBtnClick={() => floor_plan?.length && downloadFile(floor_plan?.[0].url)}
						disabled={!floor_plan || !floor_plan.length}
					/>
				</div>
				<div className={styles.photosWrapper}>
					<Field
						contentType="photosBtn"
						label="Photos"
						onPhotosBtnClick={() => onPhotoGalleryOpen(images)}
						disabled={!images || !images.length}
					/>
				</div>
			</div>
		</div>
	)
}

export default PropertyAvailabilityMobileListItem
