import React from 'react'

import Heading from '@/components/atoms/Heading'
import Text from '@/components/atoms/Text'

import * as styles from './styles.module.css'

interface Props extends Pick<PropertyType, 'name' | 'zip' | 'address' | 'city' | 'state' | 'property_types' | 'tags'> {}

export default function InfoSection({ name, zip, address, city, state, property_types, tags = [] }: Props) {
	return (
		<section className={styles.section}>
			<div className={styles.triangle} />
			<div className="container">
				<div className={styles.info}>
					<Heading level={3} variant="bold">
						{name}
					</Heading>
					<Text level={3}>
						{address}, {city}, {state} {zip}
					</Text>
					<Text level={3}>
						{property_types ? <Text level={3}>{property_types.map((t: PropertyTypeType) => t.type).join(' | ')}</Text> : null}
					</Text>
				</div>
				<div className={styles.tags}>
					<ul>
						{tags.map((tag) => (
							<li key={tag.id}>
								<Heading level={4} variant="bold">
									{tag.header}
								</Heading>
								<Text level={4}>{tag.text}</Text>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	)
}
