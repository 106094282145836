import React, { useState } from 'react'

import useMatchMedia from '@/hooks/useMatchMedia'
import PropertyDesktopGallery from '@/components/molecules/PropertyDesktopGallery'
import PropertyMobileGallery from '@/components/molecules/PropertyMobileGallery'

import PropertiesModalGallery from '@/components/molecules/PropertyModalGallery'
import * as styles from './styles.module.css'

interface Props {
	images: PropType<PropertyType, 'images'>
}
function PropertyGallery({ images }: Props) {
	const [openModal, setOpenModal] = useState(false)
	const [activeImage, setActiveImage] = useState(0)
	const isDesktop = useMatchMedia('(min-width: 1200px)')

	function handleOpenModal(imgIndex: number) {
		setOpenModal(true)
		setActiveImage(imgIndex)
	}

	return (
		<section className={styles.section_wrapper}>
			<div className="container">
				<div className="desktop">
					{isDesktop && <PropertyDesktopGallery images={images} onOpenModal={handleOpenModal} />}
				</div>
				<div className="mobile">
					{!isDesktop && <PropertyMobileGallery images={images} onOpenModal={handleOpenModal} />}
				</div>
			</div>
			<PropertiesModalGallery
				open={openModal}
				onClose={() => setOpenModal(false)}
				images={images}
				activeImage={activeImage}
			/>
		</section>
	)
}

export default PropertyGallery
