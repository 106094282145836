export default function downloadFile(url: string) {
	fetch(url)
		.then((res) => res.blob())
		.then((blob) => {
			const link = document.createElement('a')
			const blobUrl = window.URL.createObjectURL(blob)
			link.download = url.replace(/^.*[\\/]/, '')
			link.href = blobUrl
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			window.URL.revokeObjectURL(blobUrl)
		})
}
