/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react'
import Slider, { Settings } from 'react-slick'

import Image from '@/components/atoms/Image'

import SliderArrow from '@/components/atoms/SliderArrow'
import * as styles from './styles.module.css'

interface Props {
	images: PropType<PropertyType, 'images'>
	onOpenModal: (imgIndex: number) => void
}

const SLIDER_SETTINGS: Settings = {
	slidesToShow: 1,
	slidesToScroll: 1,
	infinite: false,
	dots: true,
	dotsClass: styles.button__bar,
	nextArrow: <SliderArrow type="next" additionalStyles={`${styles.arrow} ${styles.arrow_next}`} />,
	prevArrow: <SliderArrow type="prev" additionalStyles={`${styles.arrow} ${styles.arrow_prev}`} />,
}

function PropertyMobileGallery({ images = [], onOpenModal }: Props) {
	return (
		<div className={styles.wrapper}>
			<Slider {...SLIDER_SETTINGS}>
				{images.slice(0, 8).map((img, index) => (
					<div
						key={`GatsbyImage_${img.localFile.id}`}
						className={styles.image_wrapper}
						onClick={() => onOpenModal(index)}
					>
						<Image image={img} />
					</div>
				))}
			</Slider>
		</div>
	)
}

export default PropertyMobileGallery
