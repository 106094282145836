import React, { useEffect } from 'react'
import { MapContainer, Marker } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import L from 'leaflet'

import markerIcon from '@/assets/images/marker.png'

import * as styles from './styles.module.css'

interface PropertyMapProps {
	coords: PropType<PropertyType, 'coordinate'>
}

const fallbackPosition = {
	lat: 33.68,
	lng: -117.92,
}

function PropertyMap({ coords }: PropertyMapProps) {
	useEffect(() => {
		if (L) {
			L.Marker.prototype.options.icon = L.icon({
				iconUrl: markerIcon,
				iconAnchor: [12, 29]
			})
		}
	}, [])

	return (
		<section className={styles.map}>
			<MapContainer
				zoom={18}
				center={(coords && [coords.latitude, coords.longitude]) || fallbackPosition}
				scrollWheelZoom={false}
			>
				<ReactLeafletGoogleLayer apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY} />
				{coords && <Marker position={[coords.latitude, coords.longitude]} />}
			</MapContainer>
		</section>
	)
}

export default PropertyMap
