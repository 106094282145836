const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2
})

export default function renderDollarAmount(x: number | undefined) {
	if (x) {
		return formatter.format(x)
	}
	return 'Call for Price'
}
