import React from 'react'

import PropertyAvailabilityTableRow from '@/components/molecules/PropertyAvailabilityTableRow'

import * as styles from './styles.module.css'

interface PropertyAvailabilityTableProps {
	propertyUnits: PropertyUnitType[]
	onModalOpen: (propertyUnitPhotos: PropType<PropertyUnitType, 'images'> | undefined) => void
	typeKeys?: SpaceProfileKeyType[]
}

const preliminaryColumns = ['Address', 'Suite', 'Sq. Ft'] 
const finalColumns = ['Rent', 'Lease Type', 'Floorplan', 'Photos']

function PropertyAvailabilityTable({ propertyUnits, onModalOpen, typeKeys = []}: PropertyAvailabilityTableProps) {
	const columns = preliminaryColumns.concat(typeKeys, finalColumns)
	return (
		<table className={styles.table}>
			<thead>
				<tr>
					{columns.map((column) => (
						<th key={column}>{column}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{propertyUnits.map((unit) => (
					<PropertyAvailabilityTableRow
						key={unit.id}
						propertyUnit={unit}
						onModalOpen={onModalOpen} 
						includeOffice={typeKeys.includes('Office')}
						includeWarehouse={typeKeys.includes('Warehouse')}
						includeResearch={typeKeys.includes('R&D')}
					/>
				))}
			</tbody>
		</table>
	)
}

export default PropertyAvailabilityTable
