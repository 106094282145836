/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-responsive-modal'
import Slider from 'react-slick'
import clsx from 'clsx'

import Image from '@/components/atoms/Image'

import SliderArrow from '@/components/atoms/SliderArrow'
import isGatsbyAsset from '@/utils/isGatsbyAsset'

import * as styles from './styles.module.css'

interface Props {
	open: boolean
	images?: GatsbyAsset[] | RestAsset[]
	onClose: () => void
	activeImage?: number
}

function PropertiesModalGallery({ onClose, open, images = [], activeImage = 0 }: Props) {
	const [activeSlide, setActiveSlide] = useState(0)
	const [MainSlider, setMainSlider] = useState<Slider | undefined>()
	const [ThumbnailSlider, setThumbnailSlider] = useState<Slider | undefined>()

	function handleKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
		if (e.key === 'ArrowRight') {
			MainSlider?.slickNext()
		} else if (e.key === 'ArrowLeft') {
			MainSlider?.slickPrev()
		}
	}

	useEffect(() => {
		setActiveSlide(activeImage)
	}, [activeImage])

	useEffect(() => {
		if (MainSlider) {
			MainSlider?.slickGoTo(activeSlide)
		}
	}, [MainSlider])

	return (
		<Modal
			open={open}
			onClose={onClose}
			center
			showCloseIcon={false}
			classNames={{ modal: styles.modal, modalContainer: styles.modal_container, overlay: styles.overlay }}
		>
			<div className={styles.main_slider} onKeyUp={handleKeyPress} role="group">
				<Slider
					ref={(ref) => ref && setMainSlider(ref)}
					asNavFor={ThumbnailSlider}
					slidesToShow={1}
					slidesToScroll={1}
					afterChange={setActiveSlide}
					dots={false}
					swipeToSlide
					fade
					nextArrow={
						<SliderArrow type="next" additionalStyles={`${styles.main_arrow} ${styles.main_arrow_next}`} />
					}
					prevArrow={
						<SliderArrow type="prev" additionalStyles={`${styles.main_arrow} ${styles.main_arrow_prev}`} />
					}
					arrows
					responsive={[
						{
							breakpoint: 1200,
							settings: {
								arrows: false,
							},
						},
					]}
				>
					{images.map((image) =>
						isGatsbyAsset(image) ? (
							<div key={`mainImage_${image.localFile.id}`} className={styles.mainImage_wrapper}>
								<Image image={image} alt={image.alternativeText} />
							</div>
						) : (
							<div key={`mainImage_${image.id}`} className={styles.mainImage_wrapper}>
								<img src={image.url} alt={image.alternativeText} />
							</div>
						)
					)}
				</Slider>
			</div>
			<div className={styles.thumbnail_slider}>
				<Slider
					asNavFor={MainSlider}
					ref={(ref) => ref && setThumbnailSlider(ref)}
					slidesToShow={6}
					slidesToScroll={1}
					swipeToSlide
					touchMove={false}
					focusOnSelect
					afterChange={setActiveSlide}
					dots={false}
					arrows={false}
					nextArrow={
						<SliderArrow
							type="next"
							additionalStyles={`${styles.thumbnail_arrow} ${styles.thumbnail_arrow_next}`}
						/>
					}
					prevArrow={
						<SliderArrow
							type="prev"
							additionalStyles={`${styles.thumbnail_arrow} ${styles.thumbnail_arrow_prev}`}
						/>
					}
					responsive={[
						{
							breakpoint: 1200,
							settings: {
								arrows: true,
								slidesToShow: 5,
							},
						},
						{
							breakpoint: 800,
							settings: {
								slidesToShow: 4,
							},
						},
						{
							breakpoint: 630,
							settings: {
								slidesToShow: 3,
								arrows: true,
							},
						},
						{
							breakpoint: 450,
							settings: {
								slidesToShow: 2,
								arrows: true,
							},
						},
					]}
				>
					{images.map((image, index) => (
						<button
							key={`bullet_${isGatsbyAsset(image) ? image.localFile.id : image.id}`}
							type="button"
							className={clsx(styles.thumbnail, {
								[styles.thumbnail_active]: activeSlide === index,
							})}
						>
							<img src={image.url} alt={image.alternativeText} />
						</button>
					))}
				</Slider>
			</div>
		</Modal>
	)
}

export default PropertiesModalGallery
