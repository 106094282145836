import React from 'react'
import Image from '@/components/atoms/Image'

import * as styles from './styles.module.css'

export default function PropertyContact({ list = [] }: PropertyContactType) {
	return (
		<section className={styles.wrapper}>
			<div className="container">
				<div className={styles.heading}>
					<h4>Contact</h4>
					<span />
				</div>
				<div className={styles.cardWrapper}>
					{list.map((contact) => (
						<div className={styles.card} key={contact.full_name}>
							{contact.image ? (
								<div className={styles.image}>
									<Image
										image={contact.image}
										alt={contact.image.alternativeText ? contact.image.alternativeText : 'contact person'}
									/>
								</div>
							) : (
								<div className={styles.imageFallback} />
							)}
							<div className={styles.cardText}>
								<h5>{contact.full_name}</h5>
								{contact.phone_number && (
									<a data-type="phone" href={`tel:${contact.phone_number}`}>
										{contact.phone_number}
									</a>
								)}

								{contact.email && (
									<a href={` mailto:${contact.email}`} target="_blank" rel="noreferrer">
										{contact.email}
									</a>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}
