import React from 'react'

import leftArrow from '@/assets/svg/left-arrow.svg'
import rightArrow from '@/assets/svg/right-arrow.svg'

import * as styles from './styles.module.css'

interface Props {
	type: 'prev' | 'next'
	additionalStyles?: string
	onClick?: () => void
}

function SliderArrow({ type, onClick, additionalStyles = '' }: Props) {
	return type === 'next' ? (
		<button onClick={onClick} className={`${styles.arrow} ${styles.arrow_next} ${additionalStyles}`}>
			<img src={rightArrow} alt="arrow" />
		</button>
	) : (
		<button onClick={onClick} className={`${styles.arrow} ${styles.arrow_prev} ${additionalStyles}`}>
			<img src={leftArrow} alt="arrow" />
		</button>
	)
}

export default SliderArrow
