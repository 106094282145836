import React, { useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import PropertyContact from '@/components/organisms/PropertyContact'
import Layout from '@/components/organisms/Layout'
import PropertyGallery from '@/components/organisms/PropertyGallery'
import InfoSection from '@/components/organisms/PropertyInfoSection'
import PropertyAvailability from '@/components/organisms/PropertyAvailability'
import PropertyLinks from '@/components/organisms/PropertyLinks'
import PropertyMap from '@/components/molecules/PropertyMap'
import Hero from '@/components/organisms/Hero'
import Seo from '@/components/organisms/Seo'
import useQueryParams from '@/hooks/useQueryParams'
import { URL_SEARCH_PARAMS } from '@/helpers/consts'

type PropertyData = {
	strapiProperty: PropertyType
}

const transformRawRestAsset = (asset: RawAsset): RestAsset => {
	const attributes = asset.attributes
	return { id: asset.id, ...attributes }
}

export default function Property({ data, pageContext }: PageProps<PropertyData, { id: string; name: string }>) {
	const featureParam = useQueryParams(URL_SEARCH_PARAMS.FEATURE)
	const [propertyUnits, setPropertyUnits] = useState<PropertyUnitType[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const { strapiProperty } = data || {}
	const heroBg = strapiProperty.section_hero?.background
		? strapiProperty.section_hero.background
		: strapiProperty.images?.[0]
	const heroContent = strapiProperty.section_hero?.content
		? strapiProperty.section_hero.content
		: `# ${strapiProperty.name}`
	async function fetchPropertyDataLegacy() {
		setIsLoading(true)
		await fetch(`${process.env.GATSBY_STRAPI_API_URL}/api/property-units?filters[property][id][$eq]=${pageContext.id}` +
					`&populate[0]=space_profile&populate[1]=floor_plan&populate[2]=images&pagination[pageSize]=150`)
			.then((res) => res.json())
			.then((res) => {
				if (Array.isArray(res.data)) {
					const data: PropertyUnitRawType[] = res.data
					const transformedUnits = data.map((unit: PropertyUnitRawType) => {
						let attributes = unit.attributes
						const images = attributes.images?.data?.map(transformRawRestAsset)
						const floor_plan = attributes.floor_plan?.data?.map(transformRawRestAsset)
						delete attributes.images
						delete attributes.floor_plan
						
						return { id: unit.id, images: images, floor_plan: floor_plan, ...attributes }
					})
					const availableUnits = transformedUnits.filter((unit: PropertyUnitType) => unit.is_available)
					setPropertyUnits(availableUnits)
				}
			})
			.catch((err) => console.log(2, err))
		setIsLoading(false)
	}

	async function fetchPropertyData() {
		setIsLoading(true)
		await fetch(`${process.env.GATSBY_STRAPI_API_URL}/api/property-units/property/${pageContext.id}`)
			.then((res) => res.json())
			.then((res) => {
				if (Array.isArray(res.data)) {
					const data: PropertyUnitType[] = res.data
					const availableUnits = data.filter((unit: PropertyUnitType) => unit.is_available)
					setPropertyUnits(availableUnits)
				}
			})
			.catch((err) => console.log(2, err))
		setIsLoading(false)
	}

	useEffect(() => {
		if (featureParam == 'new-fetch') {
			fetchPropertyData()
		} else {
			fetchPropertyDataLegacy()
		}
	}, [pageContext.id])
	return (
		<Layout>
			<Seo title={data.strapiProperty.name} og_image={heroBg} description="This is page for Property" />
			<div className="background property">
				<Hero content={heroContent} background={heroBg} />
				<InfoSection
					name={strapiProperty.name}
					city={strapiProperty.city}
					state={strapiProperty.state}
					zip={strapiProperty.zip}
					address={strapiProperty.address}
					property_types={strapiProperty.property_types}
					tags={strapiProperty.tags}
				/>
				<PropertyLinks brochureUrl={strapiProperty.brochure?.url} websiteUrl={strapiProperty.external_website} />
				<PropertyGallery images={strapiProperty.images} />
				<PropertyAvailability propertyUnits={propertyUnits} typeKeys={strapiProperty.typeKeys}/>
				<PropertyContact list={strapiProperty.contacts} />
				<PropertyMap coords={strapiProperty.coordinate} />
			</div>
		</Layout>
	)
}

export const propertyQuery = graphql`
	query propertyQuery($id: Int!) {
		strapiProperty(strapi_id: { eq: $id }) {
			name
			id
			address
			city
			property_types {
				type
			}
			typeKeys
			brochure {
				url
			}
			external_website
			images {
				localFile {
					id
					childImageSharp {
						gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, JPG])
					}
				}
				url
				alternativeText
			}
			state
			zip
			tags {
				header
				id
				text
			}
			contacts {
				phone_number
				full_name
				email
				image {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, JPG], width: 100)
						}
					}
				}
			}
			coordinate {
				latitude
				longitude
			}
			section_hero {
				bg_dark_level
				content {
					data {
						content
					}
				}
			}
		}
	}
`
