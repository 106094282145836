/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import clsx from 'clsx'
import Image from '@/components/atoms/Image'

import * as styles from './styles.module.css'

interface Props {
	images: PropType<PropertyType, 'images'>
	onOpenModal: (imgIndex: number) => void
}

const MAX_IMAGES = 6

function chunkImages(items: GatsbyAsset[], size: number) {
	const chunkedImages = []
	const itemsCopy = [...items]

	while (itemsCopy.length) {
		chunkedImages.push(itemsCopy.splice(0, size))
	}

	return chunkedImages
}

function PropertyDesktopGallery({ images, onOpenModal }: Props) {
	const chunkedImages = images ? chunkImages(images.slice(0, MAX_IMAGES), 2) : []

	function handleOpenModal(rowIndex: number, imgIndex: number) {
		if (rowIndex === 0) {
			onOpenModal(imgIndex)
		} else if (rowIndex === 1) {
			onOpenModal(imgIndex + 2)
		} else {
			onOpenModal(imgIndex + 4)
		}
	}
	return (
		<div>
			{chunkedImages.map((array, index) => {
				const isSecondRow = (index + 1) % 2 === 0
				return (
					<div key={`row_${index + 1}`} className={styles.row}>
						{array.map((item, itemIndex) => (
							<React.Fragment key={`element_${itemIndex + 1}`}>
								{(itemIndex + 1) % 2 === 0 && (
									<div className={clsx(styles.border, { [styles.border_green]: isSecondRow })} />
								)}
								<figure onClick={() => handleOpenModal(index, itemIndex)}>
									<Image image={item} />
								</figure>
							</React.Fragment>
						))}
					</div>
				)
			})}
		</div>
	)
}

export default PropertyDesktopGallery
