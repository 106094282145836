import React from 'react'

import numberWithCommas from '@/utils/numberWithCommas'
import renderDollarAmount from '@/utils/renderDollarAmount'
import downloadFile from '@/utils/downloadFile'
import { ReactComponent as CameraIcon } from '@/assets/svg/camera.svg'

import * as styles from './styles.module.css'

type RowPropsType = {
	propertyUnit: PropertyUnitType
	onModalOpen: (propertyUnitPhotos: PropType<PropertyUnitType, 'images'> | undefined) => void
	includeOffice: boolean
	includeWarehouse: boolean
	includeResearch: boolean
}

function PropertyAvailabilityTableRow({
	propertyUnit: {
		street_address,
		suite,
		available_sq_feet,
		space_profile: { office_space_available, warehouse_space_available, research_space_avaliable },
		price_per_sq_ft,
		lease_type,
		floor_plan,
		images,
	},
	onModalOpen,
	includeOffice,
	includeWarehouse,
	includeResearch,
}: RowPropsType) {
	return (
		<tr>
			<td>{street_address}</td>
			<td>{suite}</td>
			<td>{numberWithCommas(available_sq_feet)}</td>
			{includeOffice ? <td>{numberWithCommas(office_space_available)}</td> : null}
			{includeWarehouse ? <td>{numberWithCommas(warehouse_space_available)}</td> : null}
			{includeResearch ? <td>{numberWithCommas(research_space_avaliable)}</td> : null}
			<td>{renderDollarAmount(price_per_sq_ft)}</td>
			<td>{lease_type}</td>
			<td>
				<button
					className={styles.downloadBtn}
					disabled={!floor_plan || !floor_plan.length}
					onClick={() => floor_plan?.length && downloadFile(floor_plan?.[0].url)}
				>
					Download
				</button>
			</td>
			<td>
				<button
					className={styles.photosBtn}
					onClick={() => onModalOpen(images)}
					disabled={!images || !images.length}
				>
					<CameraIcon />
				</button>
			</td>
		</tr>
	)
}

export default PropertyAvailabilityTableRow
